import EstimateCard from "./EstimateCard";
import "./CostEstimator.css";
import right from "../../assets/icons/right.svg";
import React, { useState, useRef, useEffect } from "react";
import Input from "../../components/elements/Input.js";
import Button from "../../components/elements/Button.js";
import axios from "axios";
import Loading from "../../components/modules/Loading";
import Filter from "../../components/elements/Filter";
import { MemoizedUserNavigation } from "../../components/sections/Navigation";
import useFuzzySearchQueryHandler, { handleAPIFetch } from "./utils";
import EstimateDetails from "./EstimateDetails";

function CostEstimator(props) {
  const userInputInitialState = {
    professionalNpi: "",
    institutionalNpi: "",
    zipCode: "",
    radius: "50 miles",
    plan: process.env.REACT_APP_CE_DEFAULT_PLAN,
    service: "",
    billingCodeType: "CPT",
    specialty: "",
    cursor: null,
  };
  const apiKeys = JSON.parse(process.env.REACT_APP_CE_API_KEYS);
  const plans = JSON.parse(process.env.REACT_APP_CE_PLANS);
  const defaultApiKeyName = process.env.REACT_APP_CE_API_KEY_DEFAULT;
  const [userCostEstimatorInput, setUserCostEstimatorInput] = useState(
    userInputInitialState
  );
  const [selectedApiKey, setSelectedApiKey] = useState(defaultApiKeyName);
  const [estimates, setEstimates] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inputsErrorMesage, setInputsErrorMessage] = useState(null);
  const [resultsInRadius, setResultsInRadius] = useState(null);
  const [IDToScrollIntoView, setIDToScrollIntoView] = useState(0); // this controls the estimate that is scrolled into view
  const newestEstimateRef = useRef(null);
  const SearchTypes = {
    npiSearch: "npiSearch",
    zipSearch: "zipSearch",
  };
  const [searchType, setSearchType] = useState(SearchTypes.zipSearch);
  const [matchingServices, setMatchingServices] = useState([]);
  const [matchingSpecialties, setMatchingSpecialties] = useState([]);
  const handleServiceNameChange = useFuzzySearchQueryHandler(
    "/services",
    setMatchingServices,
    "services",
    apiKeys[selectedApiKey]
  );
  const handleSpecialtiesNameChange = useFuzzySearchQueryHandler(
    "/specialties",
    setMatchingSpecialties,
    "specialties",
    apiKeys[selectedApiKey]
  );

  const handleApiSelection = (event) => {
    const selectedKey = event.target.innerHTML;
    setSelectedApiKey(selectedKey);
  };
  const [selectedEstimate, setSelectedEstimate] = useState(null);

  function ErrorComponent({ errorMessage }) {
    return <div className="error-message-return">{errorMessage}</div>;
  }

  function handleChange(e) {
    setUserCostEstimatorInput({
      ...userCostEstimatorInput,
      [e.target.name]: e.target.value,
    });
  }

  function handleNextPageScroll() {
    if (!newestEstimateRef.current) return;
    newestEstimateRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  function clearUserInput() {
    setUserCostEstimatorInput(userInputInitialState);
  }

  function handleSearchTypeChange(e) {
    e.preventDefault();
    setShowErrorMessage(false);
    clearUserInput();
    setMatchingServices([]);
    setMatchingSpecialties([]);
    searchType == SearchTypes.zipSearch
      ? setSearchType(SearchTypes.npiSearch)
      : setSearchType(SearchTypes.zipSearch);
  }

  function handleInputErrors(
    inputInstitutionalNpi,
    inputProfessionalNpi,
    inputSpecialties,
    billingCode
  ) {
    if (
      searchType === SearchTypes.npiSearch &&
      !inputInstitutionalNpi &&
      !inputProfessionalNpi
    ) {
      setInputsErrorMessage("At least one NPI must be entered");
    } else if (!billingCode) {
      setInputsErrorMessage("Please enter a valid billing code");
    }
    setShowErrorMessage(true);
  }

  function parseInputs(userCostEstimatorInput) {
    const inputProfessionalNpi =
      userCostEstimatorInput.professionalNpi === ""
        ? null
        : parseInt(userCostEstimatorInput.professionalNpi);
    const inputInstitutionalNpi =
      userCostEstimatorInput.institutionalNpi === ""
        ? null
        : parseInt(userCostEstimatorInput.institutionalNpi);
    const inputSpecialties =
      userCostEstimatorInput.specialty === ""
        ? null
        : matchingSpecialties[userCostEstimatorInput.specialty];
    const billingCode = matchingServices[userCostEstimatorInput.service];
    return {
      inputInstitutionalNpi,
      inputProfessionalNpi,
      inputSpecialties,
      billingCode,
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResultsInRadius(null);
    const {
      inputInstitutionalNpi,
      inputProfessionalNpi,
      inputSpecialties,
      billingCode,
    } = parseInputs(userCostEstimatorInput);
    if (
      (searchType == SearchTypes.npiSearch &&
        !inputInstitutionalNpi &&
        !inputProfessionalNpi) ||
      !billingCode
    ) {
      handleInputErrors(
        inputInstitutionalNpi,
        inputProfessionalNpi,
        inputSpecialties,
        billingCode
      );
      return;
    }

    setShowErrorMessage(false);
    setLoading(true);
    setErrorMessage(null);
    setEstimates(null);
    const URL = process.env.REACT_APP_CE_URL + `price/service-cost-estimator`;
    const planDetails = plans[userCostEstimatorInput.plan].memberID ? { member_id: plans[userCostEstimatorInput.plan].memberID } : {
      plan_id: plans[userCostEstimatorInput.plan].planID,
      plan_id_type: plans[userCostEstimatorInput.plan].planIDType,
      plan_name: plans[userCostEstimatorInput.plan].planName,
    }

    axios
      .post(
        URL,
        {
          search_parameters: {
            location: {
              zip_code: userCostEstimatorInput.zipCode,
              radius_in_miles: +userCostEstimatorInput.radius.split(" ")[0],
            },
            ...(searchType === SearchTypes.zipSearch &&
              inputSpecialties && { specialties: [inputSpecialties] }),
            ...(searchType === SearchTypes.npiSearch && {
              npi_numbers: {
                professional_npi_numbers: inputProfessionalNpi
                  ? [inputProfessionalNpi]
                  : [],
                institutional_npi_numbers: inputInstitutionalNpi
                  ? [inputInstitutionalNpi]
                  : [],
              },
            }),
          },
          plan_details: planDetails,
          service_details: [
            {
              billing_code_type: "CPT",
              billing_code: billingCode,
            },
          ],
          pagination: {
            count: 10,
            // If the caller is the main form submit,
            // then don't send back the cursor as that is not
            // a "show more estimates" call
            ...(e.type !== "submit" &&
              userCostEstimatorInput.cursor && {
                cursor: userCostEstimatorInput.cursor,
              }),
          },
        },
        {
          headers: {
            "x-api-key": `${apiKeys[selectedApiKey]}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (searchType == SearchTypes.zipSearch) {
          setUserCostEstimatorInput({
            ...userCostEstimatorInput,
            cursor: res.data.cursor,
          });
        }
        // If the caller is the "see more estimates" button
        // merge the old estimates with the new estimates
        // Once there is pagination for NPI search, this will become the default
        if (e.type !== "submit") {
          const updatedEstimates = [...estimates, ...res.data.estimates];
          setIDToScrollIntoView(estimates.length - 4);
          setEstimates(updatedEstimates);
          handleNextPageScroll();
        } else {
          setEstimates(res.data.estimates);
        }
        setErrorMessage(null);
        setResultsInRadius(
          `Results for CPT ${
            matchingServices[userCostEstimatorInput.service]
          } within ${userCostEstimatorInput.radius} of ${
            userCostEstimatorInput.zipCode
          }`
        );
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.error);
        setEstimates(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //when selected estimate is NOT null, the EstimateDetails is called and is rendered with selectedEstimate.
  //if selectedEstimate is null, then we will continue to show the results page
  //selectedEstimate holds the info of the card that we selected therefore passing through to estimate details for it to be shown
  return (
    <div>
      <MemoizedUserNavigation />
      {selectedEstimate ? (
        <EstimateDetails
          selectedEstimate={selectedEstimate}
          setSelectedEstimate={setSelectedEstimate}
        />
      ) : (
        <div className="cost-page-content">
          <div className="cost-container">
            {showErrorMessage && (
              <div className="npi-error">{inputsErrorMesage}</div>
            )}
            <form onSubmit={handleSubmit}>
              {searchType == "zipSearch" && (
                <Input
                  placeholder="Specialties"
                  name="specialties"
                  label="Specialties"
                  value={userCostEstimatorInput.specialty}
                  onChange={(event) => {
                    const val = event.target.value;
                    setUserCostEstimatorInput((prevData) => ({
                      ...prevData,
                      specialty: val,
                    }));
                    handleSpecialtiesNameChange(event);
                  }}
                  onSelect={(val) =>
                    setUserCostEstimatorInput((prevData) => ({
                      ...prevData,
                      specialty: val,
                    }))
                  }
                  showArrow={false}
                  readOnly={false}
                  options={Object.keys(matchingSpecialties).map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  forceAutocomplete={true}
                  required={false}
                  showAllOptions={true}
                />
              )}
              <div className="cost-row">
                <div className="cost-column">
                  {searchType == SearchTypes.npiSearch && (
                    <Input
                      placeholder="Professional NPI"
                      name="professionalNpi"
                      label="Professional NPI"
                      value={userCostEstimatorInput.professionalNpi}
                      onChange={handleChange}
                      required={false}
                    />
                  )}

                  <Input
                    placeholder="Zip Code"
                    name="zipCode"
                    value={userCostEstimatorInput.zipCode}
                    onChange={handleChange}
                    label="Zip Code"
                    required
                  />

                  <Input
                    placeholder="Service"
                    name="service"
                    label="Service"
                    value={userCostEstimatorInput.service}
                    onChange={(event) => {
                      const val = event.target.value;
                      setUserCostEstimatorInput((prevData) => ({
                        ...prevData,
                        service: val,
                      }));
                      handleServiceNameChange(event);
                    }}
                    onSelect={(val) =>
                      setUserCostEstimatorInput((prevData) => ({
                        ...prevData,
                        service: val,
                      }))
                    }
                    showArrow={false}
                    readOnly={false}
                    options={Object.keys(matchingServices).map((i) => ({
                      label: i,
                      value: i,
                    }))}
                    forceAutocomplete={true}
                    showAllOptions={true}
                  />
                </div>
                <div className="cost-column">
                  {searchType == SearchTypes.npiSearch && (
                    <Input
                      placeholder="Institutional NPI"
                      name="institutionalNpi"
                      value={userCostEstimatorInput.institutionalNpi}
                      onChange={handleChange}
                      label="Institutional NPI"
                      required={false}
                    />
                  )}

                  <Input
                    showAllOptions
                    selectOnly
                    showDropdownArrow
                    placeholder="Radius"
                    name="radius"
                    label="Radius"
                    value={userCostEstimatorInput.radius}
                    onChange={handleChange}
                    onSelect={(val) =>
                      setUserCostEstimatorInput((prevData) => ({
                        ...prevData,
                        radius: val,
                      }))
                    }
                    inputContainerClasses="access-code_input"
                    showArrow={false}
                    readOnly={false}
                    options={[
                      { val: 25, label: "25 miles" },
                      { val: 50, label: "50 miles" },
                      { val: 75, label: "75 miles" },
                      { val: 100, label: "100 miles" },
                    ]}
                    required
                  />

                  <Input
                    showAllOptions
                    selectOnly
                    showDropdownArrow
                    placeholder="Plan"
                    name="plan"
                    label="Plan"
                    value={userCostEstimatorInput.plan}
                    onChange={handleChange}
                    onSelect={(val) =>
                      setUserCostEstimatorInput((prevData) => ({
                        ...prevData,
                        plan: val,
                      }))
                    }
                    showArrow={false}
                    readOnly={false}
                    options={Object.keys(plans).map((plan) => ({
                      label: plan,
                      value: plan,
                    }))}
                    required
                  />
                </div>
              </div>
              <div className="cost-btn">
                <Button
                  label={`Search by ${
                    searchType == SearchTypes.npiSearch ? "zip" : "npi"
                  }`}
                  className="cost-btn search-by-btn"
                  class="outline-btn-blue"
                  active={true}
                  onClick={handleSearchTypeChange}
                ></Button>
                <p></p>
                <Button
                  label="Let's Go"
                  className="cost-btn"
                  class="long-btn filled"
                  active={true}
                  type="submit"
                ></Button>
                <Filter
                  noPadding
                  customClass
                  customTitle={`API key: `}
                  onClick={handleApiSelection}
                  label={<b>{selectedApiKey}</b>}
                  dropdownItems={Object.keys(apiKeys)}
                />
              </div>
            </form>
          </div>

          <div className="results-module-section">
            <div>
              <p className="results-bar"> {resultsInRadius} </p>{" "}
            </div>
            {loading && <Loading flexStartLoading />}
            {errorMessage && <ErrorComponent errorMessage={errorMessage} />}
            {estimates &&
              estimates.map((estimate, idx) => (
                <EstimateCard
                  key={idx}
                  estimate={estimate}
                  idx={idx}
                  customRef={
                    idx === IDToScrollIntoView ? newestEstimateRef : null
                  }
                  renderOONTags={selectedApiKey !== defaultApiKeyName}
                  setSelectedEstimate={
                    // this serves as a temporary, jank feature flag/protection against showing
                    // an incomplete second page
                    selectedApiKey !== defaultApiKeyName
                      ? setSelectedEstimate
                      : null
                  }
                />
              ))}
            {userCostEstimatorInput.cursor && estimates && (
              <div style={{ marginTop: "32px" }}>
                <Button
                  class="text-btn"
                  label="See more estimates"
                  onClick={handleSubmit}
                  active={userCostEstimatorInput.cursor}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default CostEstimator;
